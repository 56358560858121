import React, { useState } from "react";
import style from "./Invoice.module.css";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

function Invoice() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [printed, setPrinted] = useState(false);

  const order = JSON.parse(localStorage.getItem("orderInfo"));

  const companyName = "Zuluresh";

  return (
    <div className={style.main}>
      {loading && <LoadingScreen />}

      <div className={style.Invoice}>
        <table className={style.invoiceTable}>
          <tbody>
            <tr>
              <td className={style.invoiceHeader} colSpan="2" bgcolor="#727272">
                <h1>Zuluresh</h1>
                <p className={style.s1}>Invoice # {order.orderId}</p>
                <p className={style.s1}>
                  Order Date: {new Date().toDateString()}
                </p>
              </td>
            </tr>
            <tr>
              <td className={style.address} bgcolor="#EDEAEA">
                <h2 className={style.s2}>Billing Address</h2>
                <p className={style.s3}>Name: {order.address.name}</p>
                <p className={style.s3}>Phone No: {order.address.phone}</p>
                <p className={style.s3}>House No: {order.address.houseNo}</p>
                <p className={style.s3}>Block: {order.address.block}</p>
                <p className={style.s3}>Street: {order.address.street}</p>
                <p className={style.s3}>Landmark: {order.address.Landmark}</p>
                <p className={style.s3}>Pincode: {order.address.pincode}</p>
                <p className={style.s3}>Locality: {order.address.locality}</p>
                <p className={style.s3}>
                  Address As: {order.address.AddressAs}
                </p>
                <p className={style.s3}>
                  Delivery Slot: {order.address.deliverySlot}
                </p>
              </td>
              <td className={style.address} bgcolor="#EDEAEA">
                <h2 className={style.s2}>Shipping Address</h2>
                <p className={style.s3}>Name: {order.address.name}</p>
                <p className={style.s3}>Phone No: {order.address.phone}</p>
                <p className={style.s3}>House No: {order.address.houseNo}</p>
                <p className={style.s3}>Block: {order.address.block}</p>
                <p className={style.s3}>Street: {order.address.street}</p>
                <p className={style.s3}>Landmark: {order.address.Landmark}</p>
                <p className={style.s3}>Pincode: {order.address.pincode}</p>
                <p className={style.s3}>Locality: {order.address.locality}</p>
                <p className={style.s3}>
                  Address As: {order.address.AddressAs}
                </p>
                <p className={style.s3}>
                  Delivery Slot: {order.address.deliverySlot}
                </p>
              </td>
            </tr>
            <tr>
              <td className={style.productDetails} colSpan="2">
                <h2 className={style.s2}>Product Details</h2>
                <table className={style.productTable}>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Unit</th>
                      <th>Measure Unit</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.ProductDetails.map((item, index) => (
                      <tr key={index}>
                        <td>{item.Product_title}</td>
                        <td>{item.Product_quantity}</td>
                        <td>{item.Product_price}</td>
                        <td>{item.Product_unit}</td>
                        <td>{item.Product_measureUnit}</td>
                        <td>{item.Product_total}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td className={style.orderSummary} colSpan="2">
              <h2 className={style.s2}>Order Summary</h2>
              <hr className={style.line}/>
                <p className={style.para}><span>Payment Method:</span> {order.PaymentMethod.cod && "COD"} {order.PaymentMethod.online && "online"}</p>
                <p className={style.para}><span>MRP Total: </span>₹{order.mrpTotal}</p>
                <p className={style.para}><span>Discount:</span> ₹{order.Discount}</p>
                <p className={style.para}><span>Sub Total:</span> ₹{order.subTotal}</p>
                <p className={style.para}><span>Shipping & Handling:</span> {order.Shipping === 0 ?  "Free" : `₹${order.Shipping}`}</p>
                <hr className={style.line}/>
                <h3 className={style.para}>
                 <span>Grand Total:</span><strong>₹{order.totalPrice}</strong>
                </h3>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* Hide the print button when printing */}
      <style>
        {`
          @media print {
            button {
              display: none;
            }
          }
        `}
      </style>
      {!printed && (
        <button
          onClick={() => {
            const title = `${companyName} Invoice #${order.orderId}`;
            document.title = title;
            window.print();
            setPrinted(true);
          }}
          className={style.print_button}
        >
          Print Invoice
        </button>
      )}
    </div>
  );
}

export default Invoice;
