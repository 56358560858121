import React, { useState, useEffect } from "react";
import styles from "./UserTable.module.css"; // Import module-level CSS
import { useRecoilState, useRecoilValue } from "recoil";
import { filterOrder, loadingStatus, applyFilter } from "../../Recoil";
import axios from "axios";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const UserTable = () => {
  const [isLoading, setIsLoading] = useRecoilState(loadingStatus);
  const [orders, setOrders] = useState([]);
  const [filterDate, setFilterDate] = useRecoilState(filterOrder);
  const applyFilters = useRecoilValue(applyFilter);
  const authToken = JSON.parse(localStorage.getItem("token"));

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRowId, setCurrentRowId] = useState(null);

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setCurrentRowId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentRowId(null);
  };

  const columns = [
    { field: "userId", headerName: "User ID", width: 150 },
    { field: "number", headerName: "Number", width: 150 },
    {
      field: "_id",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <div>
          <Button
            id="fade-button"
            aria-controls={anchorEl && params.id === currentRowId ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={anchorEl && params.id === currentRowId ? 'true' : undefined}
            onClick={(event) => handleClick(event, params.id)}
          >
            <MoreVertIcon />
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={anchorEl && params.id === currentRowId}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={() => window.location.href = `/UserCart/${params.row.id}`}>Abandoned Cart</MenuItem>
            <MenuItem onClick={() => window.location.href = `/all-oder/${params.row.id}`}>All Orders</MenuItem>
          </Menu>
        </div>
      ),
    }
  ];

  useEffect(() => {
    getAlldata();
  }, [applyFilters]);

  const getAlldata = async () => {
    const headers = {
      "x-admin-token": authToken,
      "Content-Type": "application/json",
    };
    setIsLoading(true);
    try {
      const apiUrl = "https://www.backend.zuluresh.com/admin/userdata/getAllUser";
      const response = await axios.get(apiUrl, { headers });
      setOrders(response?.data?.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || "Error fetching data";
        alert(errorMessage);
      } else {
        alert("Something went wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.orderTable}>
      <h2>Total Users: {orders.length}</h2>
      <br />
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={orders.map((order) => ({
            id: order._id,
            number: order.number,
            userId: order.userId,
          }))}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5, 10, 20]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </div>
  );
};

export default UserTable;
