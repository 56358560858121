import React, { useEffect, useState } from "react";
import style from "./UpdateProduct.module.css"; // Replace with your actual styles
import NavBar from "../../Component/NavBar/NavBar";
import OptionBar from "../../Component/OptionBar/OptionBar";
import {
  addProduct,
  getAllCategory,
  getAllSubCategory,
  updateProduct,
} from "../../Api/Api";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { descriptionData, loadingStatus } from "../../Recoil";
import { useRecoilState } from "recoil";
import {
  AddCategoryButton,
  AddSubCategoryButton,
} from "../../Component/CreateButton/CreateButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import LoadingScreen from "../../Component/LoadingScreen/LoadingScreen";
import { useParams } from "react-router-dom";
import Editor from "../../Component/ReactEditor/EditorWithUseQuill";

function UpdateProduct() {
  const navigate = useNavigate();
  const [productImgs, setProductImgs] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, SetIsloading] = useRecoilState(loadingStatus);
  const [des, setDes] = useRecoilState(descriptionData);

  const [categories, setCategories] = useState([]);
  const [error, setError] = useState("");
  const { id } = useParams();
  const [subcategories, setSubCategories] = useState([]);
  const [categoriesId, setCategoriesId] = useState("");
  const [singleProduct, setSingleProduct] = useState({
    title: "",
    MRP: "",
    Stock: "",
    measureUnit: "",
    category: "",
    unit: "",
    price: "",
    sub_category: "",
    setAs: "",
    stockStatus: "", // New state for stock status
  });

  const authToken = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    getUpdatedProduct();
  }, []);

  const getUpdatedProduct = async () => {
    SetIsloading(true);
    try {
      const response = await axios.get(
        `https://www.backend.zuluresh.com/admin/product/getProduct/${id}`
      );
      setSingleProduct(response?.data?.data);
      console.log(response?.data?.data);
      setDes(response?.data?.data.description);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { response } = error;
        const errorMessage = response?.data?.message;
        SetIsloading(false);
        alert(errorMessage);
      } else {
        SetIsloading(false);
        alert("Something went wrong");
      }
    } finally {
      SetIsloading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSingleProduct((prevBlog) => ({
      ...prevBlog,
      [name]: value,
    }));
  };

  const handleAddImage = (e) => {
    const files = Array.from(e.target.files);
    setProductImgs(files);
  };

  const handleDeleteImage = (index) => {
    const newImages = [...productImgs];
    newImages.splice(index, 1);
    setProductImgs(newImages);
  };

  const handleUpdateClick = async () => {
    SetIsloading(true);
    const formdata = new FormData();
    formdata.append("title", singleProduct.title);
    formdata.append("MRP", singleProduct.MRP);
    formdata.append("Stock", singleProduct.Stock);
    formdata.append("measureUnit", singleProduct.measureUnit);
    formdata.append("description", des);
    formdata.append("category", singleProduct.category);
    formdata.append("sub_category", singleProduct.sub_category);
    formdata.append("unit", singleProduct.unit);
    formdata.append("price", singleProduct.price);
    formdata.append("setAs", singleProduct.setAs);
    productImgs.forEach((img, index) => {
      formdata.append(`productImg`, img);
    });

    try {
      const response = await updateProduct(id, formdata);
      const { status, message } = response;
      if (status) {
        console.log(message);
        alert("Updated successfully");
      } else {
        console.error(response);
        SetIsloading(false);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { response } = error;
        const errorMessage = response?.data?.message;
        setErrorMessage(errorMessage);
        SetIsloading(false);
        console.log("Error Message:", errorMessage);
      } else {
        const errorMessage = error.message;
        setErrorMessage(errorMessage);
        console.log("Network Error:", errorMessage);
        SetIsloading(false);
        alert("Something went wrong");
      }
    } finally {
      SetIsloading(false);
    }
  };

  return (
    <div className={style.main}>
      <NavBar />
      <OptionBar />
      {isLoading && <LoadingScreen />}
      <div className={style.body}>
        {productImgs.length > 0 ? (
          <div className={style.imgbox}>
            {productImgs.map((img, index) => (
              <div key={index} className={style.imageContainer}>
                <button
                  className={style.deleteButton}
                  onClick={() => handleDeleteImage(index)}
                >
                  <DeleteIcon style={{ fontSize: 14 }} />
                </button>
                <img
                  src={URL.createObjectURL(img)}
                  alt={`Product ${index}`}
                  className={style.image}
                />
              </div>
            ))}
          </div>
        ) : (
          <>
            {singleProduct?.productImg &&
              singleProduct?.productImg.map((imgs) => (
                <div className={style.image_container}>
                  <div className={style.image_box}>
                    <img src={imgs.url} alt="img" />
                  </div>
                </div>
              ))}
          </>
        )}

        <br />
        <input
          type="file"
          multiple
          onChange={handleAddImage}
          accept="image/*"
        />
        <ul className={style.list}>
          <li>
            <span>Title :</span>
            <input
              type="text"
              name="title"
              value={singleProduct.title}
              onChange={handleInputChange}
            />
          </li>
          <li>
            <span>Description:</span>
            <div className={style.quillContainer}>
              <Editor />
            </div>
          </li>
          <br />
          <br />
          <br />
          <br />
          <br />
          <li>
            <span>Category:*</span>
            <div className={style.categoryBox}>
              {error && <p style={{ color: "red" }}>{error}</p>}
              <select
                className={style.category}
                name="category"
                value={singleProduct.category}
                required={true}
              >
                <option value="">Select category</option>
                <option value={singleProduct.category}>
                  {singleProduct.category}
                </option>
              </select>
              <AddCategoryButton />
            </div>
          </li>
          <li>
            <span>Sub Category:*</span>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <div className={style.categoryBox}>
              <select
                className={style.category}
                name="sub_category"
                value={singleProduct.sub_category}
                required={true}
              >
                <option value="">Select sub category</option>
                <option value={singleProduct.sub_category}>
                  {singleProduct.sub_category}
                </option>
              </select>
              <AddSubCategoryButton />
            </div>
          </li>
          <li>
            <span>MRP:</span>
            <input
              type="text"
              name="MRP"
              value={singleProduct.MRP}
              onChange={handleInputChange}
            />
          </li>
          <li>
            <span>Price:</span>
            <input
              type="text"
              name="price"
              value={singleProduct.price}
              onChange={handleInputChange}
            />
          </li>
          <li>
            <span>Stock:</span>
            <select
              name="Stock"
              value={singleProduct.Stock}
              onChange={handleInputChange}
            >
              <option value="In Stock">In Stock</option>
              <option value="Out Of Stock">Out Of Stock</option>
            </select>
          </li>
          <li>
            <span>Unit:</span>
            <input
              type="text"
              name="unit"
              value={singleProduct.unit}
              onChange={handleInputChange}
            />
          </li>
          <li>
            <span htmlFor="measureUnit">Select a Measurement Unit:</span>
            <select
              id="measureUnit"
              name="measureUnit"
              type="text"
              value={singleProduct.measureUnit}
              onChange={handleInputChange}
            >
              <option value="">Select...</option>
              <option value="L">Liter (L)</option>
              <option value="mL">Milliliter (mL)</option>
              <option value="g">Gram (g)</option>
              <option value="kg">Kilogram (kg)</option>
              <option value="lb">Pound (lb)</option>
              <option value="oz">Ounce (oz)</option>
              <option value="t">Metric Ton (t)</option>
              <option value="ct">Carat (ct)</option>
              <option value="pts">Pieces (pts)</option>
            </select>
          </li>
          <li>
            <span>Set As:</span>
            <select
              type="text"
              name="setAs"
              value={singleProduct.setAs}
              onChange={handleInputChange}
            >
              <option value="">Select...</option>
              <option value="None">None</option>
              <option value="Best Seller">Best Seller</option>
              <option value="Best Deals">Best Deals</option>
              <option value="Combos">Combos</option>
              <option value="offers">offers</option>
            </select>
          </li>
        </ul>
        <button onClick={handleUpdateClick}>Update Product</button>
      </div>
    </div>
  );
}

export default UpdateProduct;
