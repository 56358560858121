import React from "react";
import style from "./LoadingScreen.module.css";

function LoadingScreen() {
  return (
    <div className={style.LoadingScreen}>
      <h1>Cooking in progress</h1>
      <div className={style.cooking}>
        <div className={style.bubble}></div>
        <div className={style.bubble}></div>
        <div className={style.bubble}></div>
        <div className={style.bubble}></div>
        <div className={style.bubble}></div>
        <div className={style.area}>
          <div className={style.sides}>
            <div className={style.pan}></div>
            <div className={style.handle}></div>
          </div>
          <div className={style.pancake}>
            <div className={style.pastry}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadingScreen;
