import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./userCartItem.module.css";
import { useParams } from "react-router-dom";

const UserCartItem = () => {
  const [items, setItems] = useState([]);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const authToken = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const headers = {
          "x-admin-token": authToken,
          "Content-Type": "application/json",
        };
        const response = await axios.get(
          `https://www.backend.zuluresh.com/admin/userdata/abandonCart/${id}`,
          { headers }
        );
        setItems(response?.data?.data || []);
        setUser(response?.data);
      } catch (error) {
        console.error("Error fetching the order data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderData();
  }, [id, authToken]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.main}>
      <h1 className={styles.title}>All Cart Items ({items?.items?.length}) </h1>
      <div className={styles.user_info}>
        {user?.pincodeData && (
          <div>
            <h2>User Details</h2> 
           <br/>
            <p><strong>Pincode:</strong> {user?.pincodeData?.pincode ? user?.pincodeData?.pincode : "when user will re login then pincode data will show"}</p>
            <p>
            <strong>Address:</strong>  {user?.pincodeData?.areaName}{" "} {user?.pincodeData?.district} {" "}
              {user?.pincodeData?.state}
            </p>
          </div>
        )}
      </div>
      <br/>
      <div className={styles.container}>
        {items?.items?.length === 0 && <p className={styles.p}>No Product Added To the Cart</p>}
        {items?.items?.map((item, index) => (
          <div key={index} className={styles.order}>
                  <div className={styles.orderDetails}>
                <span className={styles.orderTitle}>
                   <strong>Name :</strong>  {item?.productId?.title}
                </span>
                <span className={styles.orderQuantity}>
                <strong>Quantity :</strong>  {item?.quantity} item(s)
                </span>
                <span className={styles.orderPrice}>
                <strong>Price :</strong>₹ {item?.productId?.price * item?.quantity}
                </span>
              </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserCartItem;
