import React, { useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import BlotFormatter from 'quill-blot-formatter';
import 'quill/dist/quill.snow.css';
import { useRecoilState } from 'recoil';
import { descriptionData } from '../../Recoil';

const Editor = () => {
    const [des, setDes] = useRecoilState(descriptionData);
    const { quill, quillRef, Quill } = useQuill({
        modules: { blotFormatter: {} }
    });

    if (Quill && !quill) {
        Quill.register('modules/blotFormatter', BlotFormatter);
    }

    useEffect(() => {
        if (quill && des) {
            quill.root.innerHTML = des;
        }
    }, [quill, des]);

    useEffect(() => {
        if (quill) {
            quill.on('text-change', () => {
                // Get HTML content of the editor
                const htmlContent = quill.root.innerHTML;
                // Update the Recoil state with the HTML content
                setDes(htmlContent);
            });
        }
    }, [quill, setDes]);

    return (
        <div>
            <div ref={quillRef} />
        </div>
    );
};

export default Editor;
