import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './AllOrders.module.css';
import { useParams } from 'react-router-dom';


const AllOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const authToken = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const headers = {
            "x-admin-token": authToken,
            "Content-Type": "application/json",
          };
        const response = await axios.get(`https://www.backend.zuluresh.com/admin/userdata/getAllOrders/${id}`, { headers });
        setOrders(response?.data?.data || []); // Assuming 'data' contains the orders list
      } catch (error) {
        console.error('Error fetching the order data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (orders.length === 0) {
    return <div>No orders found.</div>;
  }

  return (
    <div className={styles.main}>
      <h1 className={styles.title}>All Orders  ({orders.length}) </h1>
      <div className={styles.container}>
        {orders.map((order, index) => (
          <div key={index} className={styles.order}>
            <div className={styles.orderHeader}>
              <div className={styles.orderImage}>
                <img src={order?.productImg?.url} alt="Product" className={styles.productImg} />
              </div>
              <div className={styles.orderDetails}>
                <span className={styles.orderId}>{order?.orderId}</span>
                <span className={styles.orderItems}>{order?.totalItems} items</span>
                <span className={styles.orderPrice}>₹ {order?.totalPrice}</span>
              </div>
              <div className={`${styles.orderStatus} ${order?.orderStatus === 'Delivered' ? styles.delivered : styles.pending}`}>
                {order?.orderStatus}
              </div>
            </div>
            <div className={styles.orderFooter}>
              <div className={styles.orderDate}>
                <p>Order Date</p>
                <p>{new Date(order?.orderDate).toLocaleString()}</p>
              </div>
              <div className={styles.deliveryDate}>
                <p>Delivery Date</p>
                <p>{order?.shippingInfo?.deliverySlot?.day}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllOrders;
