import axios from "axios";

const BASE_URL = "https://www.backend.zuluresh.com";
const authToken = JSON.parse(localStorage.getItem("token"));

// LOG IN ADMIN

export const loginAdmin = async (email, password) => {
  try {
    const response = await axios.post(`${BASE_URL}/admin/adminAuth/logIn`, {
      email,
      password,
    });
    const { status, message, data, token } = response.data;
    console.log(response);
    if (status) {
      localStorage.setItem("token", JSON.stringify(token));
      localStorage.setItem("userData", response.config.data);
      window.location.href = "/DashBoard";
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};

// Update  profile

export const UpdateAdminProfile = async (name, email, password) => {
  const headers = {
    "x-admin-token": authToken, // Ensure authToken is defined
    "Content-Type": "multipart/form-data", // Set content type to JSON
  };
  try {
    const response = await axios.put(
      `${BASE_URL}/admin/adminAuth/updateAdminProfile`,
      {
        name,
        email,
        password,
      },
      { headers }
    );

    const { status, message, data, token } = response.data;
    if (status) {
      localStorage.clear();
      window.location.href = "/";
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};

export const getAllShipping = async () => {
  try {
    const headers = {
      "x-auth-token": authToken, // Pass the token in the header
      "Content-Type": "application/json", // Set content type to JSON
    };
    const response = await axios.get(`${BASE_URL}/admin/shipping/getShipping`, {
      headers,
    });
    console.log(response, "Response from axios");

    // Directly return the data from axios response
    return response.data;
  } catch (error) {
    console.error("Error in getAllCategory function:", error);
  }
};

// add services

export const addServices = async (requestServices) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const headers = {
    "x-auth-token": token,
  };

  try {
    const response = await axios.post(
      `${BASE_URL}/admin/createService`,
      requestServices,
      { headers }
    );
    const { status, message, data } = response.data;

    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};

// add Access

export const addAccess = async (formdata) => {
  const headers = {
    "x-admin-token": authToken, // Ensure authToken is defined
    "Content-Type": "multipart/form-data", // Set content type to JSON
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/admin/adminAuth/grantAccess`,
      formdata,
      { headers }
    );
    const { status, message, data } = response.data;

    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};

// getAllProduct

export const getAllProduct = async () => {
  try {
    const headers = {
      "x-auth-token": authToken, // Pass the token in the header
      "Content-Type": "application/json", // Set content type to JSON
    };
    const response = await axios.get(
      `${BASE_URL}/admin/product/getAllproduct`,
      {
        headers,
      }
    );
    console.log(response, "Response from axios");

    // Directly return the data from axios response
    return response.data;
  } catch (error) {
    console.error("Error in getAllCategory function:", error);
  }
};

// getAllCategory

export const getAllCategory = async () => {
  try {
    const headers = {
      "x-auth-token": authToken, // Pass the token in the header
      "Content-Type": "application/json", // Set content type to JSON
    };
    const response = await axios.get(
      `${BASE_URL}/admin/categoryAndSubCategory/getAllCategory`,
      {
        headers,
      }
    );
    console.log(response, "Response from axios");

    // Directly return the data from axios response
    return response.data;
  } catch (error) {
    console.error("Error in getAllCategory function:", error);
  }
};
//UpdateOrderStatus

export const UpdateOrderStatus = async (id) => {
  try {
    const headers = {
      "x-auth-token": authToken, // Pass the token in the header
      "Content-Type": "application/json", // Set content type to JSON
    };
    const response = await axios.get(
      `${BASE_URL}admin/order/updateOrder/${id}`,
      {
        headers,
      }
    );
    console.log(response, "Response from axios");

    // Directly return the data from axios response
    return response.data;
  } catch (error) {
    console.error("Error in getAllCategory function:", error);
  }
};

// getAllSubCategory

export const getAllSubCategory = async (categoriesId) => {
  try {
    const headers = {
      "x-auth-token": authToken, // Pass the token in the header
      "Content-Type": "application/json", // Set content type to JSON
    };
    const response = await axios.get(
      `${BASE_URL}/admin/categoryAndSubCategory/getAllSubCategory/${categoriesId}`,
      {
        headers,
      }
    );
    console.log(response, "Response from axios");

    // Directly return the data from axios response
    return response.data;
  } catch (error) {
    console.error("Error in getAllCategory function:", error);
  }
};

// getAllSubCategoryProduct

export const getAllSubCategoryProduct = async (selectCate, subcategories) => {
  const headers = {
    "x-admin-token": authToken,
    "Content-Type": "application/json",
  };

  try {
    const encodedSubCategory = encodeURIComponent(subcategories);
    const encodedCategory = encodeURIComponent(selectCate);
    const response = await axios.get(
      `${BASE_URL}/admin/product/getAllproduct?category=${encodedCategory}&sub_category=${encodedSubCategory}`,
      { headers }
    );
    window.location.href = "/Product";
    const { status, message, data } = response.data;
    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const { response } = error;
      const errorMessage = response.data.message;
      alert(errorMessage);
    } else {
      alert("Something went wrong");
    }
    throw error;
  }
};

// getAllPincode

export const getAllPincode = async () => {
  try {
    const headers = {
      "x-admin-token": authToken, // Ensure authToken is defined
      "Content-Type": "multipart/form-data", // Set content type to JSON
    };
    const response = await axios.get(
      `${BASE_URL}/admin/pincodeLocation/getAllPincode`,
      {
        headers,
      }
    );
    console.log(response, "Response from axios");

    // Directly return the data from axios response
    return response.data;
  } catch (error) {
    console.error("Error in getAllCategory function:", error);
  }
};

// getAllTimeslot

export const getAllTimeslot = async () => {
  try {
    const headers = {
      "x-admin-token": authToken, // Ensure authToken is defined
      "Content-Type": "multipart/form-data", // Set content type to JSON
    };
    const response = await axios.get(`${BASE_URL}/admin/timeSlot/allSlots`, {
      headers,
    });
    console.log(response, "Response from axios");
    sessionStorage.setItem("timeslot", JSON.stringify(response?.data?.timeSlots));
    // Directly return the data from axios response
    return response.data;
  } catch (error) {
    console.error("Error in getAllCategory function:", error);
  }
};

//updateProduct

export const updateProduct = async (id, formdata) => {
  const headers = {
    "x-admin-token": authToken, // Ensure authToken is defined
    "Content-Type": "multipart/form-data", // Set content type to JSON
  };
  try {
    const response = await axios.put(
      `${BASE_URL}/admin/product/updateProduct/${id}`,
      formdata,
      { headers }
    );
    const { status, message, data } = response.data;
    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};

//DeleteProduct

export const deleteProduct = async (id) => {
  const authToken = JSON.parse(localStorage.getItem("token"));

  const headers = {
    "x-admin-token": authToken,
  };

  try {
    const response = await axios.delete(
      `${BASE_URL}/admin/product/deleteProduct/${id}`,
      { headers }
    );
    const { status, message, data } = response.data;
    if (status) {
      alert("Product deleted successfully");
      window.location.href = "/AllProduct"; // Refresh the page after deletion
    }
    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;
      alert(errorMessage);
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};

//DeletePincode

export const deletePincode = async (id) => {
  const headers = {
    "x-admin-token": authToken, // Ensure authToken is defined
    "Content-Type": "multipart/form-data", // Set content type to JSON
  };
  try {
    const response = await axios.delete(
      `${BASE_URL}/admin/pincodeLocation/deletePincode/${id}`,
      { headers }
    );
    const { status, message, data } = response.data;
    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};

//DeleteTimeSlot

export const DeleteTimeslot = async (id) => {
  const headers = {
    "x-admin-token": authToken, // Ensure authToken is defined
    "Content-Type": "multipart/form-data", // Set content type to JSON
  };
  try {
    const response = await axios.delete(
      `${BASE_URL}/admin/timeslot/deleteTimeSlot/${id}`,
      { headers }
    );
    const { status, message, data } = response.data;
    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};

//DeleteAllTimeSlot

export const DeleteAllTimeslot = async (id) => {
  const headers = {
    "x-admin-token": authToken, // Ensure authToken is defined
    "Content-Type": "multipart/form-data", // Set content type to JSON
  };
  try {
    const response = await axios.delete(
      `${BASE_URL}/admin/timeslot/deleteAllTimeSlots`,
      { headers }
    );
    const { status, message, data } = response.data;
    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};

//DeleteBannner

export const DeleteBanner = async (id) => {
  const headers = {
    "x-admin-token": authToken, // Ensure authToken is defined
    "Content-Type": "multipart/form-data", // Set content type to JSON
  };
  try {
    const response = await axios.delete(
      `${BASE_URL}/admin/banner/deleteSingleBanner/${id}`,
      { headers }
    );
    const { status, message, data } = response.data;
    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};

//DeleteCategory

export const DeleteCategory = async (id) => {
  const headers = {
    "x-admin-token": authToken, // Ensure authToken is defined
    "Content-Type": "multipart/form-data", // Set content type to JSON
  };
  try {
    const response = await axios.delete(
      `${BASE_URL}/admin/categoryAndSubCategory/deleteSingleCategory/${id}`,
      { headers }
    );
    const { status, message, data } = response.data;
    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};
//DeleteShipping

export const DeleteShipping = async (id) => {
  const headers = {
    "x-admin-token": authToken, // Ensure authToken is defined
    "Content-Type": "multipart/form-data", // Set content type to JSON
  };
  try {
    const response = await axios.delete(
      `${BASE_URL}/admin/shipping/deleteShipping/${id}`,
      { headers }
    );
    const { status, message, data } = response.data;
    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};

//DeleteSubCategory

export const DeleteSubCategory = async (id) => {
  const headers = {
    "x-admin-token": authToken, // Ensure authToken is defined
    "Content-Type": "multipart/form-data", // Set content type to JSON
  };
  try {
    const response = await axios.delete(
      `${BASE_URL}/admin/categoryAndSubCategory/deleteSingleSubCategory/${id}`,
      { headers }
    );
    const { status, message, data } = response.data;
    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};

//addProduct

export const addProduct = async (formData) => {
  const headers = {
    "x-admin-token": authToken, // Ensure authToken is defined
    "Content-Type": "multipart/form-data", // Set content type to JSON
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/admin/product/addProduct`,
      formData,
      { headers }
    );
    const { status, message, data } = response.data;
    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};

//addNotification

export const addNotification = async (formData) => {
  const headers = {
    "x-admin-token": authToken, // Ensure authToken is defined
    "Content-Type": "multipart/form-data", // Set content type to JSON
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/admin/notification/notifyAll`,
      formData,
      { headers }
    );
    const { status, message, data } = response.data;
    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};

//addCategory

export const addCategory = async (formData) => {
  const headers = {
    "x-admin-token": authToken, // Ensure authToken is defined
    "Content-Type": "multipart/form-data", // Set content type to JSON
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/admin/categoryAndSubCategory/addCategory`,
      formData,
      { headers }
    );
    const { status, message, data } = response.data;
    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};
//addShipping

export const addShipping = async (formData) => {
  const headers = {
    "x-admin-token": authToken, // Ensure authToken is defined
    "Content-Type": "multipart/form-data", // Set content type to JSON
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/admin/shipping/shippingCharge`,
      formData,
      { headers }
    );
    const { status, message, data } = response.data;
    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};

//addSubCategory

export const addSubCategory = async (selectedCategory, formData) => {
  const headers = {
    "x-admin-token": authToken, // Ensure authToken is defined
    "Content-Type": "multipart/form-data", // Set content type to JSON
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/admin/categoryAndSubCategory/addSubCategory/${selectedCategory}`,
      formData,
      { headers }
    );
    const { status, message, data } = response.data;
    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};

//addBanner

export const addBanner = async (formData) => {
  const headers = {
    "x-admin-token": authToken, // Ensure authToken is defined
    "Content-Type": "multipart/form-data", // Set content type to JSON
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/admin/banner/createBanner`,
      formData,
      { headers }
    );
    const { status, message, data } = response.data;
    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};

//addTimeSlot

export const addTimeSlot = async (formData) => {
  const headers = {
    "x-admin-token": authToken, // Ensure authToken is defined
    "Content-Type": "application/json", // Set content type to JSON
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/admin/timeSlot/addTimeSlot`,
      formData,
      { headers }
    );
    const { status, message, data } = response.data;
    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};

//addPincode

export const addPincode = async (formData) => {
  const headers = {
    "x-admin-token": authToken, // Ensure authToken is defined
    "Content-Type": "multipart/form-data", // Set content type to JSON
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/admin/pincodeLocation/createPincode`,
      formData,
      { headers }
    );
    const { status, message, data } = response.data;
    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};

//getSingleProduct

export const getSingleProduct = async (id) => {
  const headers = {
    "x-admin-token": authToken, // Ensure authToken is defined
    "Content-Type": "multipart/form-data", // Set content type to JSON
  };
  try {
    const response = await axios.get(`${BASE_URL}/admin/get/product/${id}`, {
      headers,
    });
    const { status, message, data } = response.data;
    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};

// getAllOrders

export const getAllOrders = async () => {
  try {
    const headers = {
      "x-admin-token": authToken, // Pass the token in the header
      "Content-Type": "multipart/form-data", // Set content type to JSON
    };
    const response = await axios.get(`${BASE_URL}/admin/order/getAllOrders`, {
      headers,
    });
    console.log(response, "Response from axios");

    // Directly return the data from axios response
    return response.data;
  } catch (error) {
    console.error("Error in getAllCategory function:", error);
  }
};

// getAllAccess

export const getAllAccess = async () => {
  try {
    const headers = {
      "x-admin-token": authToken, // Pass the token in the header
      "Content-Type": "multipart/form-data", // Set content type to JSON
    };
    const response = await axios.get(
      `${BASE_URL}/admin/adminAuth/getAllProvidedAccess`,
      {
        headers,
      }
    );
    console.log(response, "Response from axios");

    // Directly return the data from axios response
    return response.data;
  } catch (error) {
    console.error("Error in getAllCategory function:", error);
  }
};
// deleteAllAccess

export const deleteAllAccess = async (id) => {
  try {
    const headers = {
      "x-auth-token": authToken, // Pass the token in the header
      "Content-Type": "application/json", // Set content type to JSON
    };
    const response = await axios.delete(
      `${BASE_URL}/admin/adminAuth/deleteSingle/${id}`,
      {
        headers,
      }
    );
    console.log(response, "Response from axios");

    // Directly return the data from axios response
    return response.data;
  } catch (error) {
    console.error("Error in getAllCategory function:", error);
  }
};

export const getParticularOrders = async (id) => {
  const headers = {
    "x-admin-token": authToken, // Ensure authToken is defined
    "Content-Type": "multipart/form-data", // Set content type to JSON
  };
  try {
    const response = await axios.get(
      `${BASE_URL}/admin/order/getSingleOrder/${id}`,
      { headers }
    );
    console.log(response, "response");
    localStorage.setItem("orderInfo", JSON.stringify(response.data.orderData));
    const { status, message, data } = response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};

// getAllBanner

export const getAllBanner = async () => {
  const headers = {
    "x-admin-token": authToken, // Ensure authToken is defined
    "Content-Type": "multipart/form-data", // Set content type to JSON
  };
  try {
    const response = await axios.get(`${BASE_URL}/public/getAllBanner`, {
      headers,
    });
    console.log(response, "response");
    const { status, message, data } = response.data;

    return { status, message, data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;

      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      alert("Something went wrong");
    }
  }
};
