import React, { useEffect, useState } from "react";
import style from "./AllProduct.module.css";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { AiFillEdit } from "react-icons/ai";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { AiOutlinePlus } from "react-icons/ai";
import Header from "../../Component/Header/Header";
import OptionBar from "../../Component/OptionBar/OptionBar";
import NavBar from "../../Component/NavBar/NavBar";
import {  getAllCategory, getAllSubCategory, getAllSubCategoryProduct } from "../../Api/Api";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../Component/LoadingScreen/LoadingScreen";
import axios from "axios";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function AllProduct() {
  const [subCategories, setSubCategories] = useState([]);
  const { id } = useParams();
  const [categories, setCategory] = useState([]);
  const [isLoading, SetIsloading] = useState(false);
  const [Loading, Setloading] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [selectCate,SetSeclected] =  useState("")


  const authToken = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    if (!authToken) {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    handleGetAllCategory();
  }, []);

  const handleGetAllCategory = async () => {
    Setloading(true);
    try {
      const response = await getAllCategory()
      setCategory(response.data);
    } catch (error) {
      console.error("Error getting categories:", error.message);
    } finally {
        Setloading(false);
    }
  };
  

  const handleGetAllSubCategoryProduct = async (subcategories) => {
    SetIsloading(true);
    try {
      const response = await getAllSubCategoryProduct(selectCate,subcategories);
      localStorage.setItem("subCategoryproduct", JSON.stringify(response.data));   
    } catch (error) {
      console.error("Error getting subcategories:", error.message);
    } finally {
      SetIsloading(false);

    }
  };

  const handleAllSubCategory = async (categoryId,cateName) => {
    SetSeclected(cateName)
    SetIsloading(true);
    try {
      const response = await getAllSubCategory(categoryId);
      // Add an id property to each subcategory
      const subCategoriesWithId = response.data.map((subcategory, index) => ({
        ...subcategory,
        id: index + 1, // You can generate a unique id based on your data or use index
        subCategoryImg: subcategory.subCategoryImg.url, // Add the subCategoryImg to the subcategory data
      }));
      setSubCategories(subCategoriesWithId); // Set the subcategories data
    } catch (error) {
      console.error("Error getting subcategories:", error.message);
    } finally {
      SetIsloading(false);
    }
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };

  return (
    <div className={style.main}>
      <NavBar />
      <OptionBar />
      {Loading && <LoadingScreen />}
      <div className={style.body}>
        <Header />
        <div className={style.header}>
          <h2>Products</h2>
          <div>
            <Link to="/CreateProduct">
              <button style={{ backgroundColor: "blue", color: "white" }}>
                <AiOutlinePlus />
                Create
              </button>
            </Link>
          </div>
        </div>
        <br />
        <div>
          {categories.map((cate) => (
            <div key={cate._id}>
              <Accordion
                expanded={expandedAccordion === cate._id}
                onChange={handleAccordionChange(cate._id)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  onClick={() => handleAllSubCategory(cate._id,cate.categoryName)}
                >
                  <div className={style.Accordion}>
                    <div className={style.cate_imgbox}>
                      <img src={cate.categoryImg.url} alt="category" />
                    </div>
                    <div>
                      <h5>{cate.categoryName}</h5>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {isLoading && <p>Loading...</p>}
                  {subCategories.length == 0  &&  <p>No sub category for this category</p>}
                  <div className={style.subcate_box}>
                    {subCategories.map((subcate) => (
                      <div
                        key={subcate._id}
                        className={style.inner_subcate_box}
                        onClick={() => handleGetAllSubCategoryProduct(subcate.subCategoryName)}
                      >
                        <div className={style.cate_imgbox}>
                          <img src={subcate.subCategoryImg} alt="" />
                        </div>
                        <p> {subcate.subCategoryName}</p>
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AllProduct;
