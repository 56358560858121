import React, { useState, useEffect } from "react";
import styles from "./DeliveredOrderTable.module.css"; // Import module-level CSS
import { getParticularOrders } from "../../Api/Api";
import { useRecoilState, useRecoilValue } from "recoil";
import { filterOrder, loadingStatus, applyFilter } from "../../Recoil";
import axios from "axios";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { UpdateOrderStatus } from "../CreateButton/CreateButton"; // Ensure the import path is correct

const DeliveredOrderTable = () => {
  const [isLoading, setIsLoading] = useRecoilState(loadingStatus);
  const [orders, setOrders] = useState([]);
  const [filterDate, setFilterDate] = useRecoilState(filterOrder);
  const applyFilters = useRecoilValue(applyFilter);
  const authToken = JSON.parse(localStorage.getItem("token"));

  const currentDate = new Date();

  console.log(orders, "orders");

  const columns = [
    { field: "orderId", headerName: "Order ID", width: 150 },
    { field: "deliverySlot", headerName: "Delivery Slot", width: 150 },
    { field: "startTime", headerName: "Start Time", width: 150 },
    { field: "endTime", headerName: "End Time", width: 150 },
    { field: "customerName", headerName: "Customer Name", width: 150 },
    { field: "phoneNo", headerName: "Phone No", width: 150 },
    { field: "houseFlatNo", headerName: "House Flat No", width: 150 },
    { field: "blockName", headerName: "Block Name", width: 150 },
    { field: "street", headerName: "Street", width: 150 },
    { field: "landMark", headerName: "LandMark", width: 150 },
    { field: "pinCode", headerName: "PinCode", width: 150 },
    { field: "locality", headerName: "Locality", width: 150 },
    { field: "shippingPrice", headerName: "Shipping Price", width: 150 },
    { field: "totalPrice", headerName: "Total Price", width: 150 },
    { field: "totalItems", headerName: "Total Items", width: 150 },
    { field: "orderStatus", headerName: "Order Status", width: 150 },
    { field: "paymentMethod", headerName: "Payment Method", width: 150 },
    {
      field: "_id",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <p
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => handleParticularData(params.row._id)}
        >
          View Invoice
        </p>
      ),
    },
    {
      field: "update",
      headerName: "Update Status",
      width: 150,
      renderCell: (params) => (
        <UpdateOrderStatus id={params.row._id} /> // Pass the id to UpdateOrderStatus
      ),
    },
  ];

  useEffect(() => {
    getAlldata();
  }, [applyFilters]);

  const getAlldata = async () => {
    const headers = {
      "x-admin-token": authToken, // Ensure authToken is defined
      "Content-Type": "application/json", // Set content type to JSON
    };
    setIsLoading(true);
    try {
      let apiUrl = "https://www.backend.zuluresh.com/admin/order/getAllDeliveredOrders";
      if (applyFilters) {
        apiUrl += `?date=${filterDate}`;
      }
      const response = await axios.get(apiUrl, { headers });
      setOrders(response?.data?.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Axios error (HTTP error)
        const { response } = error;
        // Set the error message
        const errorMessage = response.data.message;

        alert(errorMessage);
        // Log the error message as a string
      } else {
        // Network error (e.g., no internet connection)
        alert("Something went wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleParticularData = async (id) => {
    setIsLoading(true);
    try {
      await getParticularOrders(id);
    } catch (error) {
      console.error("Error fetching order details:", error);
    } finally {
      setIsLoading(false);
      window.location.href = "/Invoice";
    }
  };

  function convertToJSX(htmlString) {
    return React.createElement("div", {
      dangerouslySetInnerHTML: { __html: htmlString },
    });
  }

  const getRowClassName = (params) => {
    switch (params.row.orderStatus) {
      case "Pending":
        return styles.pending;
      case "Processing":
        return styles.processing;
      case "Shipped":
        return styles.shipped;
      case "Out For Delivery":
        return styles.outForDelivery;
      case "Delivered":
        return styles.delivered;
      case "Cancelled":
        return styles.cancelled;
      default:
        return "";
    }
  };

  return (
    <div className={styles.orderTable}>
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={orders.map((order, index) => ({
            id: index + 1, // Ensure each row has a unique identifier
            orderId: order.orderId,
            deliverySlot: order.shippingInfo?.deliverySlot?.day,
            startTime: order.shippingInfo?.deliverySlot?.startTime,
            endTime: order.shippingInfo?.deliverySlot?.endTime,
            customerName: order.shippingInfo?.name,
            phoneNo: order.shippingInfo?.phoneNo,
            houseFlatNo: order.shippingInfo?.houseFlatNo,
            blockName: order.shippingInfo?.blockName,
            street: order.shippingInfo?.street,
            landMark: order.shippingInfo?.landMark,
            pinCode: order.shippingInfo?.pinCode,
            locality: order.shippingInfo?.locality,
            shippingPrice: order.shippingPrice,
            totalPrice: order.totalPrice,
            totalItems: order.totalItems,
            orderStatus: order.orderStatus,
            paymentMethod: order.paymentMethod.cod ? "COD" : "Online",
            _id: order?._id,
          }))}
          columns={columns}
          getRowClassName={getRowClassName}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </div>
  );
};

export default DeliveredOrderTable;
