import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Product from './Pages/Product/Product';
import {RecoilRoot} from  "recoil"
import LoginForm from './Component/Form/LoginForm';
import OptionBar from './Component/OptionBar/OptionBar';
import DashBoard from './Pages/DashBoard/DashBoard';
import Orders from './Pages/Orders/Orders';
import Payment from './Pages/Payment/Payment';
import Customers from './Pages/Customers/Customers';
import Sales from './Pages/Sales/Sales';
import PeopleOnline from './Pages/PeopleOnline/PeopleOnline';
import UpdateProduct from './Pages/UpdateProduct/UpdateProduct';
import CreateProduct from './Pages/CreateProduct/CreateProduct';
import Pincode from './Pages/Pincode/Pincode';
import TimeSlot from './Pages/TimeSlot/TimeSlot';
import Banner from './Pages/Banner/Banner';
import AddCategory from './Pages/AddCategory/AddCategory';
import { AddSubCategoryButton } from './Component/CreateButton/CreateButton';
import AddSubCategory from './Pages/AddSubCategory/AddSubCategory';
import Invoice from './Component/Invoice/Invoice';
import Access from './Pages/Access/Access';
import UpdateProfile from './Component/UpdateProfile/UpdateProfile';
import AllProduct from './Pages/AllProduct/AllProduct';
import AddShpping from './Pages/AddShpping/AddShpping';
import AddNotification from './Pages/AddNotification/AddNotification';
import DeliveredOrders from './Pages/DeliveredOrders/DeliveredOrders';
import UserCart from './Pages/UserCart/UserCart';
import AllOderPage from './Pages/AllOderPage/AllOderPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
  {
    path: "/DashBoard",
    element: <App/>,
  },
  {
    path: "/Product",
    element: <Product/>,
  },
  {
    path: "/AllProduct",
    element: <AllProduct />,
  },
  {
    path: "/*",
    element: <LoginForm/>,
  },
  {
    path: "/OptionBar",
    element: <OptionBar/>,
  },
  {
    path: "/Orders",
    element: <Orders />,
  },
  {
    path: "/DeliverOrder",
    element: <DeliveredOrders />,
  },
  {
    path: "/Payment",
    element: <Payment />,
  },
  {
    path: "/Customers",
    element: <Customers />,
  },
  {
    path: "/Sales",
    element: <Sales />,
  },
  {
    path: "/PeopleOnline",
    element: <PeopleOnline />,
  },
  {
    path: "/UpdateProduct/:id", // Corrected path with parameter
    element: <UpdateProduct />,
  },
  {
    path: "/CreateProduct", // Corrected path with parameter
    element: <CreateProduct />,
  },
  {
    path: "/Pincode", // Corrected path with parameter
    element: <Pincode />,
  },
  {
    path: "/TimeSlot", // Corrected path with parameter
    element: <TimeSlot />,
  },
  {
    path: "/Banner", // Corrected path with parameter
    element: <Banner />,
  },
  {
    path: "/AddCategory", // Corrected path with parameter
    element: <AddCategory />,
  },
  {
    path: "/AddShpping", // Corrected path with parameter
    element: <AddShpping />,
  },
  {
    path: "/AddSubCategory", // Corrected path with parameter
    element: <AddSubCategory />,
  },
  {
    path: "/Invoice", // Corrected path with parameter
    element: <Invoice />,
  },
  {
    path: "/Access", // Corrected path with parameter
    element: <Access />,
  },
  {
    path: "/AddNotification", // Corrected path with parameter
    element: <AddNotification />,
  },
  {
    path: "/Profile", // Corrected path with parameter
    element: <UpdateProfile />,
  },
  {
    path: "/UserCart/:id", // Corrected path with parameter
    element: <UserCart />,
  },
  {
    path: "/all-oder/:id", // Corrected path with parameter
    element: <AllOderPage />,
  },
]);
root.render(
  <RecoilRoot>
  <RouterProvider router={router} />
  </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
