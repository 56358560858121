import React from 'react'
import style from "./Customers.module.css"
import Header from '../../Component/Header/Header'
import NavBar from '../../Component/NavBar/NavBar';
import OptionBar from '../../Component/OptionBar/OptionBar';
import UserTable from '../../Component/UserTable/UserTable';


function Customers() {
  return (
    <div className={style.main}>
      <NavBar/>
      <OptionBar/>
      <div className={style.body}>
      <Header/>
      <br/>
      <div className={style.infobox}>
      <UserTable />
      </div>
      </div>
    </div>
  )
}

export default Customers
