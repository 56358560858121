import React from 'react'
import style from "./AllOderPage.module.css"
import Header from '../../Component/Header/Header'
import NavBar from '../../Component/NavBar/NavBar';
import OptionBar from '../../Component/OptionBar/OptionBar';
import AllOrders from '../../Component/AllOrders/AllOrders';


function AllOderPage() {
  return (
    <div className={style.main}>
      <NavBar/>
      <OptionBar/>
      <div className={style.body}>
      <Header/>
      <br/>
      <div className={style.infobox}>
      <AllOrders/>
      </div>
      </div>
    </div>
  )
}

export default AllOderPage
